<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">Хүргэлтийн бүсүүд</h3>
    </div>
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="partner-google-map">
          <gmap-map
            :center="center"
            :zoom="12"
            :options="mapOptions"
            :draggable="true"
            style="width:100%;  height: 100%; margin-top: 5px;"
          >
            <gmap-polygon
              v-for="(item, index) in polygons"
              :key="index"
              :paths="item.polygon"
              :title="item.zone_name"
              :options="{
                fillColor: '#000000',
                strokeColor: 'grey',
                strokeOpacity: 0.4,
                fillOpacity: 0.1
              }"
              @mouseover="statusText = item.zone_name"
              @mousedown="editZones(item.id)"
              @mouseout="statusText = null"
            >
            </gmap-polygon>
            <div slot="visible" v-if="statusText">
              <div
                style="bottom: 0; left: 20; background-color: #0000FF; color: white; padding:20px; position: absolute; z-index: 100"
              >
                {{ statusText }}
              </div>
            </div>
          </gmap-map>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import * as axios from "axios";
export default {
  name: "DeliveryZone",
  mounted() {
    this.getZones();
  },
  data() {
    return {
      statusText: null,
      polygons: null,
      mapOptions: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: "greedy"
      },
      center: {
        lat: parseFloat(47.9174),
        lng: parseFloat(106.9137)
      }
    };
  },
  methods: {
    async getZones() {
      try {
        const axiosResponse = await axios.get(
          process.env.VUE_APP_DRIVER_API + "admin/delivery-zone/getPolygons"
        );
        if (axiosResponse.data.status !== "success") {
          throw new Error(axiosResponse.data.error.message);
        }
        this.polygons = axiosResponse.data.response.data;
      } catch (err) {
        this.$notify.error({
          title: "Алдаа",
          message: err.message
        });
      }
    },
    async editZones(id) {
      this.$router.push({ path: "edit-driver-zone", query: { id: id } });
    }
  }
};
</script>
<style scoped>
.el-form-item {
  margin-bottom: 10px;
}
.el-table .cell {
  padding: 0 5px;
}
</style>
